import _ from "lodash"

import { apiUrl } from "env"

async function apiFetch(relativeUrl) {
    try {
        const url = apiUrl + relativeUrl
        // const headers = await getAuthHeaders();

        const response = await fetch(url, {
            method: "GET",
            // headers
        })
        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
        }
        const json = await response.json()
        return json
    }
    catch (e) {
        throw new Error(e);
    }
}

export async function getConfig() {
    const result = await apiFetch(`/tax/config/load`)
    return result
}

const flowBasePath = "/tax/flow"

// export function fetchSections(customerId) {
//     return async () => {
//         const result = await apiFetch(`${flowBasePath}/sections`)
//         return result
//     }
// }

export function fetchTOC(customerId) {
    return async () => {
        const result = await apiFetch(`${flowBasePath}/toc`)
        return result
    }
}

export function fetchSection(customerId,path,params) {
    return async () => {
        const params_str = _.join(_.map(params,(v,k) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`),"&")
        const result = await apiFetch(`${flowBasePath}/sections/${path}/panel?${params_str}`)
        return result
    }
}

