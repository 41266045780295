/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { apiUrl  } from "env"

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
// import bgImage from "assets/images/tunnel-background.png";

import logo from "assets/images/InsureTax Logo.svg"

function Basic() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const nextRelativePath = searchParams.get('next') || '/'
  const nextPath = nextRelativePath
  // const baseUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
  // const nextPath = `${baseUrl}/${nextRelativePath}`

  const [rememberMe, setRememberMe] = useState(false);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLogin = (provider) => {
    let loginPath = `${apiUrl}/tax/login/${provider}`;
    loginPath += `?next=${encodeURIComponent(nextPath)}`

    window.location.href = loginPath
  };

  return (
    // <BasicLayout image={bgImage}>
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          // bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={2}
          p={2}
          mb={1}
          textAlign="center"
        >
          <img src={logo} className="App-logo" alt="logo" />
          <MDTypography variant="h4" fontWeight="medium" color="#817fff" mt={1} sx={{ color: "#817fff" }}>
            Welcome to InsureTax
          </MDTypography>
        </MDBox>
        <MDBox pt={2} pb={0} px={3} className="it_oauth_buttons">
            <button onClick={() => handleLogin('google')}><GoogleIcon /> Login with Google</button>
            {/* <button onClick={() => handleLogin('linkedin')}><LinkedInIcon /> Login with LinkedIn</button> */}
            <button onClick={() => handleLogin('microsoft')}><MicrosoftIcon /> Login with Microsoft</button>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
